<template>
  <div class="about">
    <!--====== PAGE TITLE PART START ======-->

    <div class="page-title-area">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="page-title-item text-center">
              <h2 class="title">Our Recent Work</h2>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="index.php">Kibbutz Tech</a>
                  </li>
                </ol>
              </nav>
            </div>
            <!-- page title -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
    </div>

    <!--====== PAGE TITLE PART ENDS ======-->
    <div class=" news-area news-area-2">
      <div class="container">
    <div class="row pt-3 mx-2">
          <div class="col-lg-3 mb-4">
            <div class="intri-content pa-2" style="border-style: solid; background-color: white;">
              <a href="http://www.hemithexim.com/" target="_blank">
                <img style="height:100px" src="assets/images/hemithexim.png" />
              </a>
            </div>
          </div>

          <div class="col-lg-3 mb-4">
            <div class="intri-content pa-2" style="border-style: solid; background-color: white;">
              <a href="https://app.kinderway.com/" target="_blank">
                <img style="height:100px" src="assets/images/kinderway_logo1.png" />
              </a>
            </div>
          </div>

          <div class="col-lg-3 mb-4">
            <div class="intri-content pa-2" style="border-style: solid; background-color: white;">
              <a href="https://innoplore.com/" target="_blank">
                <img style="height:100px" src="assets/images/innoplore-logo.png" />
              </a>
            </div>
          </div>

          <div class="col-lg-3 mb-4">
            <div class="intri-content pa-2" style="border-style: solid;  background-color: white;">
              <a href="https://www.meragi.com/" target="_blank">
                <img style="height:100px" src="assets/images/miragi.png" />
              </a>
            </div>
          </div>

          <div class="col-lg-3 mb-4">
            <div class="intri-content pa-2" style="border-style: solid;">
              <a href="http://bellottitile.com/" target="_blank">
                <img style="height:100px" src="assets/images/bellotti-logo.png" />
              </a>
            </div>
          </div>

          <div class="col-lg-3 mb-4">
            <div class="intri-content pa-2" style="border-style: solid; background-color: white;">
              <a href="https://blessedhearts.in/" target="_blank">
                <img
                  click="openImg()"
                  style="height:100px"
                  src="assets/images/blessedhearts_logo.png"
                />
              </a>
            </div>
          </div>

          <div class="col-lg-3 mb-4">
            <div class="intri-content pa-2" style="border-style: solid; background-color: white;">
              <a href="https://www.optimize-courier.com/" target="_blank">
                <img style="height:100px" src="assets/images/optimize.png" />
              </a>
            </div>
          </div>

          <div class="col-lg-3 mb-4">
            <div class="intri-content pa-2" style="border-style: solid; ">
              <a href="http://livart.in/" target="_blank" style="padding-left: 40px;">
                <img style="height:100px" src="assets/images/livart.png" />
              </a>
            </div>
          </div>

          <div class="col-lg-3 mb-4">
            <div class="intri-content pa-2" style="border-style: solid;">
              <a href="http://www.palplastind.com/" target="_blank">
                <img style="height:100px" src="assets/images/palplastind.png" />
              </a>
            </div>
          </div>

          <div class="col-lg-3 mb-4">
            <div class="intri-content pa-2" style="border-style: solid;">
              <a href="https://aladinseo.com" target="_blank" style=" padding-left: 60px;">
                <img style="height:100px" src="assets/images/aladinseo.png" />
              </a>
            </div>
          </div>

          <div class="col-lg-3 mb-4">
            <div class="intri-content pa-2" style="border-style: solid; background-color: white;">
              <a href="https://orunexim.com/" target="_blank">
                <img style="height:100px" src="assets/images/orunexim.png" />
              </a>
            </div>
          </div>

          <div class="col-lg-3 mb-4">
            <div class="intri-content pa-2" style="border-style: solid;">
              <a href="http://astia.in/" target="_blank">
                <img style="height:100px" src="assets/images/astia_logo.png" />
              </a>
            </div>
          </div>
        </div>
      </div>
      </div>
    <!-- <div class="about-intro-area pt-120">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <div class="intri-content">
              <h3 class="text-1">KIBBUTZ HISTORY</h3>
              <p
                class="text-2"
              >Our story began in 2014, and we now have the opportunity of connecting with millions of people every day through amazing products and services in countries all over the world. We develop and deploy digital solutions to address important business concerns at Kibbutz.</p>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="intri-content">
              <h3 class="text-1">Kibbutz Mission</h3>
              <p
                class="text-2"
              >Our website and app development should be succinct and eloquent, with a lot of depth and effect. Choose your words carefully—avoid challenges, hollow phrases, or mission statements that may apply to a variety of businesses and we adhere to that. Our mission is to help our customers' businesses grow by providing creative design, development, and market-defining high-quality solutions that add value and provide a reliable competitive advantage. To achieve effective results, we combine in-depth functional knowledge, international standards, and holistic people.</p>
              Be clear and specific, and you’ll highlight the core of what makes your venture unique and worthwhile.
            </div>
          </div>
          <div class="col-lg-4">
            <div class="intri-content">
              <h3 class="text-1">Kibbutz Vision</h3>
              <p
                class="text-2"
              >We understand that no two customer landscapes are the same. As a result, our methodology includes a specific solution tailored to the clients' needs. In order to align IT goals with the overall vision of the organization, we offer a phased approach toward your business drivers. Our key goal is to continuously improve and establish ourselves as a top performer in this cutthroat worldwide market. Fortunately, we were able to put together a team of experts that can form and mould their combined experiences and who all have exceptional talent that can help your firm advance. To make Kibbutz the best provider of software development and technology services in the country and worldwide. In an oncoming era of technology, we want to create an environment that denotes IT abundance, with innovative and stylish web development and software solutions</p>
            </div>
          </div>
        </div>
      </div>
    </div>-->
  </div>
</template>


<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  mounted() {
    // console.log("this.$route.params",this.$route.params.name)
    window.scrollTo(0, 0);
  }
};
</script>